import React from 'react'
import Layout from '../components/Layout'
import {StaticImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'
import Seo from '../components/Seo'


const Projects = () => {
    return (
        <Layout>
            <Seo  title="Projecten"/>
            <main className="page">
            <section className="about-page">
                <article>
                <h5>Onze aanpak</h5>
                    <p>
                        De ambities van onze klanten zijn leidend bij onze projectaanpak.
                    </p>
                    <p>
                    Samen met de klant bepalen we de best passende inzet van AWS
                        om de gestelde doelen te realiseren. 
                    </p>
                    <p>
                    We versterken het draagvlak voor projecten door de organisatie en stakeholders 
                    mee te nemen in de ambities en projectresultaten. 
                    </p>
                    <Link to="/contact" className="btn">
                        contact
                    </Link>
                </article>
                <StaticImage 
                src="../assets/images/projectsphoto.jpg" 
                alt="People working together" 
                className = "about-img"
                placeholder ="blurred"/>
            </section>
            </main>
        </Layout>
    )
}

export default Projects